import {
  dayjsToTimestamp,
  strictTimestampToDayjs,
  timestampToDayjs,
} from "@/lib/utils";
import {
  Activity,
  Creator,
  Currency,
  GCSData,
  PostDetail,
  PostInfo,
  PostType,
  Agent,
  Candidate,
  SettleItem,
} from "@lctech-tw/post-proto/dist/js/external/atom/atom_pb";
import { PostStatus } from "@lctech-tw/post-proto/dist/js/external/enums/enums_pb";
import dayjs, { Dayjs } from "dayjs";
import { Image, State } from "./uploader";
const humanStatus = new Map([
  [PostStatus.CLOSE, "關閉"],
  [PostStatus.ENROLL, "報名中"],
  [PostStatus.CHECKIN, "本日報到"],
  [PostStatus.SETTLEMENT, "結算中"],
  [PostStatus.DONE, "已結束"],
  [PostStatus.ALLSTATUS, "全部"],
]);
class CreatorInfo {
  name: string;
  constructor(payload: { ctr?: Creator; name?: string }) {
    if (payload.ctr) {
      this.name = payload.ctr.getName();
    } else if (payload.name) {
      this.name = payload.name;
    } else {
      this.name = "";
    }
  }
  toCreator(): Creator {
    const creator = new Creator();
    creator.setName(this.name);
    return creator;
  }
}
class PostListItem {
  id: number;
  orderNumber: string;
  name: string;
  type: Item;
  time: string;
  originalStatus: PostStatus;
  status = "";
  isActivated: boolean;
  isActivationVisible: boolean;
  creator: CreatorInfo;
  isPublic: boolean;
  isAvailableToCheckIn: boolean;
  location: string;
  isOnSettle = false;
  canForwardToSettlement = false;
  constructor(info: PostInfo) {
    this.id = info.getId();
    this.orderNumber = info.getPostNo();
    this.name = info.getName();
    this.type = new Item({
      id: info.getPostType()?.getId(),
      name: info.getPostType()?.getName(),
    });
    this.creator = new CreatorInfo({ ctr: info.getCreator() });
    this.isPublic = info.getIsPublic();
    this.originalStatus = info.getStatus();
    this.setStatus(info.getStatus());
    this.isActivated = info.getStatus() === PostStatus.CLOSE ? false : true;
    this.isActivationVisible =
      info.getStatus() === PostStatus.ENROLL ||
      info.getStatus() === PostStatus.CLOSE
        ? true
        : false;
    this.isAvailableToCheckIn = info.getStatus() === PostStatus.CHECKIN;
    const from = timestampToDayjs(info.getPostStartAt());
    const to = timestampToDayjs(info.getPostEndAt());
    if (from && to) {
      this.time = from.format("YYYY-MM-DD HH:mm~");
      this.time += to.format("HH:mm");
    } else {
      this.time = "-";
    }
    this.location = info.getAddress();
  }
  setStatus(status: PostStatus): void {
    this.originalStatus = status;
    this.status = humanStatus.get(status) || "-";
    this.isActivated = status === PostStatus.CLOSE ? false : true;
    this.isActivationVisible =
      status === PostStatus.ENROLL || status === PostStatus.CLOSE
        ? true
        : false;
    this.isOnSettle = status === PostStatus.SETTLEMENT;
    this.canForwardToSettlement = status !== PostStatus.DONE;
  }
}

class MyPostStatus {
  name: string;
  value: PostStatus;
  constructor(postStatus: PostStatus) {
    this.name = humanStatus.get(postStatus) || "-";
    this.value = postStatus;
  }
}

const MyPostStatusList: MyPostStatus[] = [
  new MyPostStatus(PostStatus.ALLSTATUS),
  new MyPostStatus(PostStatus.CHECKIN),
  new MyPostStatus(PostStatus.CLOSE),
  new MyPostStatus(PostStatus.DONE),
  new MyPostStatus(PostStatus.ENROLL),
];

enum SearchScope {
  range = "range",
  name = "name",
  uid = "uid",
  order = "order",
  creator = "creator",
}

class MySearchScope {
  name: string;
  value: SearchScope;
  constructor(payload: { name: string; value: SearchScope }) {
    this.name = payload.name;
    this.value = payload.value;
  }
}

const SearchScopeList: MySearchScope[] = [
  new MySearchScope({ name: "日期", value: SearchScope.range }),
  new MySearchScope({ name: "藝人名稱", value: SearchScope.name }),
  new MySearchScope({ name: "UID", value: SearchScope.uid }),
  new MySearchScope({ name: "通告編號", value: SearchScope.order }),
  new MySearchScope({ name: "建立者", value: SearchScope.creator }),
];

class PostForm {
  name = "";
  isHidden = false;
  staffList: MyAgent[] = [];
  activityId: number | undefined = undefined;
  postTypeId: number | undefined = undefined;
  vacancyAmount = 0;
  ladyList: MyCandidate[] = [];
  price = 0;
  currencyId: number | undefined = undefined;
  isPriceHidden = false;
  time: [Dayjs | undefined, Dayjs | undefined] = [undefined, undefined];
  location = "";
  content = "";
  memo = "";
  imageList: Image[] = [];

  toPostDetail(): PostDetail {
    const postDetail = new PostDetail();
    postDetail.setActivity(
      this.activityId ? new Activity().setId(this.activityId) : undefined
    );
    postDetail.setAddress(this.location);
    postDetail.setAgentsList(this.staffList.map((e) => e.toAgent()));
    postDetail.setContent(this.content);
    postDetail.setCurrency(
      this.currencyId ? new Currency().setId(this.currencyId) : undefined
    );
    postDetail.setDisplayPrice;
    !this.isPriceHidden;
    postDetail.setQty(this.vacancyAmount);
    postDetail.setImagesList(
      this.imageList
        .filter((e) => e.state === State.uploaded)
        .map((e) => e.toGcsData())
    );
    postDetail.setIsPublic(!this.isHidden);
    postDetail.setCandidatesList(this.ladyList.map((e) => e.toCandidate()));
    postDetail.setMemo(this.memo);
    postDetail.setName(this.name);
    postDetail.setPostStartAt(dayjsToTimestamp(this.time[0]));
    postDetail.setPostEndAt(dayjsToTimestamp(this.time[1]));
    postDetail.setPostType(
      this.postTypeId ? new PostType().setId(this.postTypeId) : undefined
    );
    postDetail.setPrice(this.price);
    // useless
    postDetail.setProductItem;
    return postDetail;
  }
}

class MyActivity {
  name: string;
  id: number;
  constructor(info: Activity) {
    this.name = info.getName();
    this.id = info.getId();
  }
}

class MyPostType {
  name: string;
  id: number;
  constructor(info: PostType) {
    this.name = info.getName();
    this.id = info.getId();
  }
}

class MyCurrency {
  name: string;
  id: number;
  constructor(info: Currency) {
    this.name = info.getName();
    this.id = info.getId();
  }
}
class Item {
  id: number;
  name: string;
  constructor(payload: { id: number | undefined; name: string | undefined }) {
    this.id = payload.id || 0;
    this.name = payload.name || "";
  }
}
class MyPostDetail {
  id = -1;
  logo = "";
  name = "";
  orderNumber = "";
  isPublic = false;
  staffList: MyAgent[] = [];
  activity: Item = new Item({ id: 0, name: "" });
  postType: Item = new Item({ id: 0, name: "" });
  vacancyAmount = 0;
  ladyList: MyCandidate[] = [];
  price = 0;
  currency: Item = new Item({ id: 0, name: "" });
  isPriceHidden = false;
  time: { humanTime: string; from: Dayjs; to: Dayjs } = {
    humanTime: "",
    from: dayjs(),
    to: dayjs(),
  };
  location = "";
  content = "";
  memo = "";
  imageList: string[] = [];
  registerUrl = "";
  isSettleable = false;
  canCheckIn = false;
  originalStatus: PostStatus = PostStatus.CLOSE;
  constructor(detail?: PostDetail) {
    if (detail) {
      this.id = detail.getId();
      this.name = detail.getName();
      this.orderNumber = detail.getPostNo();
      this.isPublic = detail.getIsPublic();
      this.staffList = detail.getAgentsList().map((e) => new MyAgent(e));
      this.activity = new Item({
        id: detail.getActivity()?.getId(),
        name: detail.getActivity()?.getName(),
      });
      this.postType = new Item({
        id: detail.getPostType()?.getId(),
        name: detail.getPostType()?.getName(),
      });
      this.logo = detail.getProductItem()?.getLogo() || "";
      this.vacancyAmount = detail.getQty();
      this.ladyList = detail.getCandidatesList().map((e) => new MyCandidate(e));
      this.price = detail.getPrice();
      this.currency = new Item({
        id: detail.getCurrency()?.getId(),
        name: detail.getCurrency()?.getName(),
      });
      this.isPriceHidden = detail.getDisplayPrice();
      const from = detail.getPostStartAt();
      const to = detail.getPostEndAt();
      if (from && to) {
        this.time.from = strictTimestampToDayjs(from);
        this.time.to = strictTimestampToDayjs(to);
        this.time.humanTime =
          strictTimestampToDayjs(from).format("YYYY年M月D日 HH:mm~");
        this.time.humanTime += strictTimestampToDayjs(to).format("HH:mm");
      } else {
        this.time.humanTime = "-";
      }
      this.location = detail.getAddress();
      this.content = detail.getContent();
      this.memo = detail.getMemo();
      this.imageList = detail.getImagesList().map((e) => e.getPublicUrl());
      this.registerUrl = `https://control.jkface.net/post/${this.id}`;
      this.isSettleable = detail.getStatus() === PostStatus.SETTLEMENT;
      this.canCheckIn = detail.getStatus() === PostStatus.CHECKIN;
      this.originalStatus = detail.getStatus();
    }
  }
  isSelectable(): boolean {
    // 只要在活動日前都可以篩選女郎
    // return this.time.from.startOf("day").isAfter(dayjs());
    return this.originalStatus === PostStatus.ENROLL;
  }
  toPostForm(): PostForm {
    const postForm = new PostForm();
    postForm.name = this.name;
    postForm.isHidden = !this.isPublic;
    postForm.staffList = this.staffList;
    postForm.activityId = this.activity.id;
    postForm.postTypeId = this.postType.id;
    postForm.vacancyAmount = this.vacancyAmount;
    postForm.ladyList = this.ladyList;
    postForm.price = this.price;
    postForm.currencyId = this.currency.id;
    postForm.isPriceHidden = this.isPriceHidden;
    postForm.time = [this.time.from, this.time.to];
    postForm.location = this.location;
    postForm.content = this.content;
    postForm.memo = this.memo;
    return postForm;
  }
}

class MyAgent {
  uid: number;
  objectId: string;
  name: string;
  avatar: string;
  constructor(
    user:
      | Agent
      | {
          uid: number;
          objectId: string;
          name: string;
          avatar: string;
        }
  ) {
    if (user instanceof Agent) {
      this.uid = user.getUid();
      this.objectId = user.getObjectId();
      this.name = user.getDisplayName();
      this.avatar = user.getAvatarUrl();
    } else {
      this.uid = user.uid;
      this.objectId = user.objectId;
      this.name = user.name;
      this.avatar = user.avatar;
    }
  }

  toAgent(): Agent {
    const agent = new Agent();
    agent.setAvatarUrl(this.avatar);
    agent.setDisplayName(this.name);
    agent.setObjectId(this.objectId);
    agent.setUid(this.uid);
    return agent;
  }
}

class MyCandidate {
  uid: number;
  objectId: string;
  name: string;
  avatar: string;
  isRegistered = false;
  isSelected = false;
  price = 0;
  constructor(
    user:
      | Candidate
      | {
          uid: number;
          objectId: string;
          name: string;
          avatar: string;
        }
  ) {
    if (user instanceof Candidate) {
      this.uid = user.getUid();
      this.objectId = user.getObjectId();
      this.name = user.getDisplayName();
      this.avatar = user.getAvatarUrl();
      this.isRegistered = user.getIsEnroll();
      this.isSelected = user.getIsSelected();
      this.price = user.getPrice();
    } else {
      this.uid = user.uid;
      this.objectId = user.objectId;
      this.name = user.name;
      this.avatar = user.avatar;
    }
  }

  toCandidate(): Candidate {
    const candidate = new Candidate();
    candidate.setUid(this.uid);
    candidate.setObjectId(this.objectId);
    candidate.setDisplayName(this.name);
    candidate.setAvatarUrl(this.avatar);
    candidate.setIsEnroll(this.isRegistered);
    candidate.setIsSelected(this.isSelected);
    candidate.setPrice(this.price);
    return candidate;
  }
}

class MyCheckIn {
  uid: number;
  objectId: string;
  name: string;
  avatar: string;
  isSelected: boolean;
  isCheckIn: boolean;
  checkInAt: {
    timestamp: Dayjs | undefined;
    humanTime: string;
  };
  constructor(log: Candidate) {
    this.uid = log.getUid();
    this.objectId = log.getObjectId();
    this.name = log.getDisplayName();
    this.avatar = log.getAvatarUrl();
    this.isSelected = log.getIsSelected();
    this.isCheckIn = Boolean(log.getCheckInAt());
    this.checkInAt = {
      timestamp: timestampToDayjs(log.getCheckInAt()),
      humanTime:
        timestampToDayjs(log.getCheckInAt())?.format("YYYY-MM-DD HH:mm:ss") ||
        "-",
    };
  }
}

class MySettlement {
  uid: number;
  objectId: string;
  name: string;
  avatar: string;
  isSelected: boolean;
  isCheckIn: boolean;
  checkInAt: {
    timestamp: Dayjs | undefined;
    humanTime: string;
  };
  defaultPrice: number;
  price: number | undefined;
  isCheckout = false;
  constructor(log: Candidate) {
    this.uid = log.getUid();
    this.objectId = log.getObjectId();
    this.name = log.getDisplayName();
    this.avatar = log.getAvatarUrl();
    this.isSelected = log.getIsSelected();
    this.isCheckIn = Boolean(log.getCheckInAt());
    this.checkInAt = {
      timestamp: timestampToDayjs(log.getCheckInAt()),
      humanTime:
        timestampToDayjs(log.getCheckInAt())?.format("YYYY-MM-DD HH:mm:ss") ||
        "-",
    };
    this.defaultPrice = log.getPrice();
  }
  toSettleItem(): SettleItem {
    const item = new SettleItem();
    item.setObjectId(this.objectId);
    item.setPrice(this.price || -1);
    return item;
  }
}

export {
  PostListItem,
  MyPostStatus,
  MyPostStatusList,
  MySearchScope,
  SearchScopeList,
  SearchScope,
  CreatorInfo,
  PostForm,
  MyActivity,
  MyPostType,
  MyCurrency,
  MyPostDetail,
  MyAgent,
  MyCandidate,
  MyCheckIn,
  MySettlement,
};
