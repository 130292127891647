import { GCSData } from "@lctech-tw/post-proto/dist/js/external/atom/atom_pb";

enum State {
  uploading = "上傳中",
  uploaded = "已上傳",
  error = "錯誤",
  deleted = "移除",
  idle = "閒置",
}
class Image {
  randomId: number;
  url: string;
  path = "";
  state: State = State.idle;
  constructor(url: string) {
    this.randomId = Math.random();
    this.url = url;
  }
  humanStatus(): string {
    return this.state;
  }
  toGcsData(): GCSData {
    const data = new GCSData();
    data.setGcsPath(this.path);
    data.setPublicUrl;
    return data;
  }
}

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(typeof reader.result === "string" ? reader.result : "");
    };
    reader.onerror = (error) => reject(error);
  });
}

export { Image, getBase64, State };
